<template>
  <div
    class="card"
    :class="[
      'plan-' + plan.id,
      cardClasses
    ]"
  >
    <div class="card-body">
      <div class="title-box">
        <h4>{{ plan.title }} </h4>
        <p class="card-text">{{ plan.description }}</p>
      </div>
      <Features
        :plan="plan"
      />
      <Addons
        ref="addons"
        class="mt-2"
        :plan="plan"
        :should-be-editable="shouldBeEditable"
        @quantity-changed="quantityChanged($event)"
      />
      <PricingDetails
        class="mt-2"
        :plan="plan"
        :addon-quantity="currentQuantity"
        :plan-price="planPrice"
      />
    </div>
    <div class="card-footer">
      <div class="d-grid">
        <button
          class="btn btn-primary text-center"
          :disabled="!shouldBeEditable"
          @click="onSelectPlan()"
        >
          {{ selectButtonTittle }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Features from './Features'
import Addons from './Addons'
import PricingDetails from './PricingDetails'

export default {
  name: 'Plan',
  components: { Features, Addons, PricingDetails },
  props: {
    plan: Object,
    selectedPlan: String,
    shouldBeEditable: Boolean
  },
  data () {
    return {
      currentQuantity: 0
    }
  },
  computed : {
    cardClasses () {
      return {
        'selected': this.isSelectedPlan,
        'discarded': this.selectedPlan !== null && !this.isSelectedPlan
      }
    },
    planPrice () {
      let totalPrice = 0
      totalPrice += this.plan.price
      totalPrice += this.currentQuantity * this.plan.addon.price
      return totalPrice + '€'
    },
    isSelectedPlan () {
      return this.selectedPlan === this.plan.id
    },
    selectButtonTittle () {
      return this.isSelectedPlan ? 'Selected!' : 'Select'
    }
  },
  methods: {
    onSelectPlan () {
      if (this.selectedPlan === this.plan.id) {
        this.planChanged('set-empty')
        return
      }
      this.planChanged()
    },
    quantityChanged (quantity) {
      this.currentQuantity = quantity
      if (this.isSelectedPlan) {
        this.planChanged()
      }
    },
    setQuantity (quantity) {
      this.$refs.addons.setQuantity(quantity)
    },
    planChanged (condition) {
      return condition
        ? this.$emit('plan-changed', { id: null, addonQuantity: null })
        : this.$emit('plan-changed', { id: this.plan.id, addonQuantity: this.currentQuantity, planPrice: this.planPrice})
    }
  }
}
</script>

<style lang="sass" scoped>

.card
  margin-bottom: 1.5em

.features
  margin-top: 20px

.features-title-box
  display: flex
  align-items: center
  margin-bottom: 10px

.features-title
  font-weight: 600
  color: #2c3f50
  margin-left: 5px
  margin-bottom: 0px

.feature
  margin: 10px 0px
  display: flex
  justify-content: space-between
  align-items: center
  padding-inline-start: 10px

.title-box
  min-height: 140px

</style>
