<template>
  <table>
    <thead>
      <th style="width:25px">
        <i class="fas fa-shopping-cart"/>
      </th>
      <th colspan="2">
        Total
      </th>
    </thead>
    <tbody>
      <tr>
        <td />
        <td>
          <p class="mb-0"> {{ plan.title }} </p>
        </td>
        <td v-if="addonQuantity === 0" class="text-end">
          <h4 class="mb-0 text-end"> {{ plan.price + '€' }} </h4>
        </td>
        <td v-if="addonQuantity !== 0" class="text-end">
          <p class="mb-0"> {{ plan.price + '€' }} </p>
        </td>
      </tr>
      <tr>
        <template v-if="addonQuantity > 0">
          <td/>
          <td>
            + {{ addonQuantity * 500 }} referencias
          </td>
          <td class="text-end">
            {{ plan.addon.price * addonQuantity + '€'}}
          </td>
        </template>
      </tr>
      <tr v-if="addonQuantity !== 0">
        <td/>
        <td><b>Total</b></td>
        <td><h4 class="mb-0 text-end"> {{ planPrice }}</h4></td>
      </tr>
      <tr>
        <td colspan="3" class="monthly-notice"> Facturación mensual</td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'PricingDetails',
  props: {
    plan: Object,
    addonQuantity: Number,
    planPrice: String
  }
}
</script>

<style lang="sass" scoped>

.monthly-notice
  text-align: end
  font-size: 12px

</style>
