<template>
  <div class="row justify-content-center align-items-center">
    <div class="d-none d-md-block col-md-5 col-xl-4">
      <div class="image-container">
        <img class="background-signup" src="../../assets/images/mail.jpg">
      </div>
    </div>
    <div class="col-10 col-md-6 col-xl-4">
      <h5 class="text-start">Todo listo!</h5>
      <p class="mb-0">En las próximas horas te enviaremos por email el enlace de pago.</p>
      <p>
        Revisa
        <span
          v-tooltip="emailTooltipText"
          class="email-hint"
        >
          tu email
        </span>
        y sigue las instrucciones para
        <a
          href="https://pccomponentes.boardfy.com/login"
          target="_blank"
        >
          iniciar sesión
          <i class="fas fa-external-link-alt" />
        </a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LastStep',
  props: {
    company: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    emailTooltipText () {
      return this.company && this.company.userMail
        ? this.company.userMail
        : ''
    }
  }
}

</script>

<style lang="sass" scoped>

@import ../../assets/css/_colors

.outlook
  color: #0078d4

.email-hint
  color: $color-primary
  cursor: pointer

</style>
