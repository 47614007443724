<template>
  <div>
    <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-fullscreen-sm-down modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="supportModalLabel">Soporte Boardfy</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="d-none d-lg-block col-lg-6">
                <div class="image-container">
                  <img class="background-signup p-5" src="../../assets/images/support.png">
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <template v-if="!requestCompleted">
                  <h5>
                    Planes personalizados
                  </h5>
                  <p>
                    Explícanos que tipo de producto es de tu interés y nos comunicaremos contigo rápidamente
                  </p>
                  <CustomInput
                    v-model="coupon"
                    disabled
                    label="Coupon"
                  />
                  <CustomInput
                    v-model="name"
                    disabled
                    label="Name"
                  />
                  <CustomInput
                    v-model="mail"
                    disabled
                    label="Email"
                  />
                  <label
                    for="reasonTextArea"
                    class="form-label"
                  >
                    Motivo
                  </label>
                  <textarea
                    v-model="reason"
                    placeholder="¿Que tipo de producto deseas contratar?"
                    class="form-control"
                    :class="reasonInputClass"
                    id="reasonTextArea"
                    rows="3"
                  />
                  <div
                    v-if="supportRequestFailed"
                    class="mt-2 mb-2"
                  >
                    <p class="fw-bold text-danger mb-0">Ha ocurrido un error procesando la solicitud, por favor inténtalo mas tarde</p>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <h5>
                      ¡Todo Listo!
                    </h5>
                    <p>
                      Nos comunicaremos contigo en breve para darte la mejor solución adaptada a tus necesidades.
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {{ requestCompleted ? 'Salir' : 'Cancelar'}}
            </button>
            <button
              v-if="!requestCompleted"
              @click="requestSupport"
              type="button"
              :disabled="!reasonValid"
              class="btn btn-primary"
            >
              <template v-if="supportRequestLoading">
                <span class="spinner-border spinner-border-sm spinner-white" role="status" aria-hidden="true" />
              </template>
              {{ supportRequestLoading ?  'Procesando' : 'Enviar'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "./CustomInput.vue"
import SignUp from '../../api/SignUp'

export default {
  name: 'SupportModal',
  components: { CustomInput },
  props: {
    supportDetails: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      coupon: '',
      name: '',
      mail: '',
      reason: '',
      supportRequestLoading: false,
      supportRequestFailed: false,
      requestCompleted: false
    }
  },
  activated () {
    if (Object.keys(this.supportDetails).length > 0) {
      this.coupon = this.supportDetails.coupon
      this.name = this.supportDetails.userName
      this.mail = this.supportDetails.userMail
    }
  },
  computed: {
    reasonValid () {
      return this.reason.length > 10
    },
    reasonInputClass () {
      if (this.reason === '') {
        return ''
      }
      return this.reasonValid ? 'is-valid' : 'is-invalid'
    }
  },
  methods: {
    requestSupport () {
      const supportDetails = {
        coupon: this.coupon,
        name: this.name,
        mail: this.mail,
        reason: this.reason
      }
      this.supportRequestLoading = true
      SignUp.requestSupport(supportDetails)
        .then(() => {
          this.requestCompleted = true
          this.supportRequestFailed = false
        })
        .catch(() => {
          this.supportRequestFailed = true
        })
        .finally(() => {
          this.supportRequestLoading = false
        })
    }
  }
}
</script>
