<template>
  <div class="row justify-content-center align-items-center">
    <div class="d-none d-md-block col-md-5 col-xl-4">
      <div class="image-container">
        <img class="background-signup" src="../../assets/images/signup.jpg">
      </div>
    </div>
    <div class="col-10 col-md-6 col-xl-4">
      <h5 class="text-start">Introduce tu Cupón
        <LoadingSpinner v-if="loadingCouponValidation" />
      </h5>
      <CustomInput
        label="Cupón"
        :is-valid="couponIsValid"
        :disabled="couponIsValid"
        :invalidReason="couponInvalidReason"
        :validReason="couponValidReason"
        @input="validateCoupon"
      />
    </div>
    <div class="col-10 col-md-11 col-xl-8 text-end">
      <StepButtons
        :showBackButton="false"
        :next-allowed="nextAllowed"
        @next="$emit('next')"
      />
    </div>
  </div>
</template>
<script>
import CustomInput from '../shared/CustomInput.vue'
import StepButtons from '../shared/StepButtons.vue'
import SignUp from '../../api/SignUp'
import LoadingSpinner from '../shared/LoadingSpinner.vue'

export default {
  name: 'CouponStep',
  components: { CustomInput, StepButtons, LoadingSpinner },
  data () {
    return {
      coupon: '',
      couponIsValid: null,
      couponInvalidReason: 'Este cupón no es válido 🚫',
      couponValidReason: 'Tu cupón es válido!',
      loadingCouponValidation: false
    }
  },
  activated () {
    document.title = 'Introduce tu Cupón | Crea una cuenta'
  },
  methods: {
    validateCoupon(couponCode) {
      this.coupon = couponCode
      if (!this.isCouponFormatValid()){
        this.couponIsValid = false
        return
      }
      this.loadingCouponValidation = true
      const data = {
        coupon: this.coupon
      }
      return SignUp.postCouponData(data)
        .then(response => {
          this.$emit('sign-info', response)
          this.couponIsValid = true
        })
        .catch(() => {
          this.couponIsValid = false
        })
        .finally(() => {
          this.loadingCouponValidation = false
        })
    },
    isCouponFormatValid () {
      const couponFormatExpresion = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/
      return couponFormatExpresion.test(this.coupon)
    }
  },
  computed: {
    nextAllowed () {
      return this.couponIsValid
    }
  }
}
</script>

<style lang="sass" scoped>

.account-exist-box
  margin-top: 1em
  > p
    margin-bottom: 0px
    text-align: center
a
  font-weight: normal

</style>
