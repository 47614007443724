<template>
  <div id="app">
    <div class="container-fluid">
      <div class="row justify-content-center vh-100">
        <div class="d-none d-xl-block col-xl-3">
          <div class="d-flex align-items-end h-100">
            <div class="brand-box">
              <img class="logo-image" src="./assets/logo_combined.png">
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-9">
          <SignUp />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignUp from './components/SignUp.vue'

export default {
  name: 'App',
  components: { SignUp }
}
</script>

<style lang="sass">
  @import "assets/css/main.sass"
</style>

<style lang="sass" scoped>

.container
  > .row
    padding-top: 100px

.logo-image
  width: 200px

.brand-box
  margin-bottom: 100px
  margin-left: 50px

</style>