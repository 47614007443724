<template>
  <div class="row justify-content-center align-items-center">
    <div class="d-none d-md-block col-md-5 col-xl-4">
      <div class="image-container">
        <img class="background-signup" src="../../assets/images/signup.jpg">
      </div>
    </div>
    <div class="col-10 col-md-6 col-xl-4">
      <h5 class="text-start">Tu información</h5>
      <CustomInput
        v-model="name"
        :disabled="!shouldBeEditable"
        :is-valid="isNameValid"
        label="Nombre"
      />
      <CustomInput
        v-model="companyName"
        :disabled="!shouldBeEditable"
        :is-valid="isCompanyNameValid"
        label="Nombre de tu empresa"
      />
      <CustomInput
        v-model="email"
        :disabled="!shouldBeEditable"
        :is-valid="isEmailValid"
        label="Email"
        type="email"
      />
      <div class="email-feedback">
        <p v-if="isEmailInvalidReason" class="text-danger mb-0 fw-bold">
          {{ isEmailInvalidReason }}
        </p>
        <LoadingSpinner v-if="emailValidationLoading" />
      </div>
    </div>
    <div class="col-10 col-md-11 col-xl-8 text-end">
      <StepButtons
        :next-allowed="nextAllowed"
        @next="$emit('next')"
        @back="$emit('back')"
      />
    </div>
  </div>
</template>

<script>
import CustomInput from '../shared/CustomInput.vue'
import StepButtons from '../shared/StepButtons.vue'
import LoadingSpinner from '../shared/LoadingSpinner.vue'
import SignUp from '../../api/SignUp'

export default {
  name: 'CompanyStep',
  components: { CustomInput, StepButtons, LoadingSpinner },
  props: {
    isPayed: Boolean,
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      name: '',
      isNameValid: null,
      companyName: '',
      isCompanyNameValid: null,
      email: '',
      isEmailValid: null,
      isEmailInvalidReason: '',
      emailTimeout: null,
      emailValidationLoading: false
    }
  },
  watch: {
    name() {
      this.companyChanged()
      this.isNameValid = this.name.length >= 2
    },
    companyName () {
      this.companyChanged()
      this.isCompanyNameValid = this.companyName.length > 2
    },
    email () {
      this.companyChanged()
      this.checkEmailValid()
    }
  },
  created () {
    this.hydrateInfo()
  },
  activated () {
    document.title = 'Tu Información | Crea una cuenta'
    if (this.company && this.company !== null) {
      this.name = this.company.userName
      this.companyName = this.company.companyName
      this.email = this.company.userMail
      this.isNameValid = this.isCompanyNameValid = this.isEmailValid = true
    }
  },
  computed: {
    shouldBeEditable () {
      return !this.isPayed
    },
    nextAllowed () {
      return this.isNameValid && this.isCompanyNameValid && this.isEmailValid
    }
  },
  methods: {
    companyChanged () {
      const companyDetails = {
        companyName: this.companyName,
        userName: this.name,
        userMail: this.email
      }
      this.$emit('company-changed', companyDetails)
    },
    checkEmailValid () {
      if (!this.isEmailFormatValid()) {
        this.isEmailValid = false
        return
      }
      if (this.emailTimeout !== null) {
        clearTimeout(this.emailTimeout)
      }
      this.emailTimeout = setTimeout(() => {
        this.checkApiEmail()
      }, 600)
    },
    checkApiEmail () {
      this.emailValidationLoading = true
      return SignUp.checkEmailAvailable(this.email)
        .then(() => {
          this.isEmailValid = true
          this.isEmailInvalidReason = ''
        })
        .catch((errorCode) => {
          if (errorCode === 409) {
            this.isEmailInvalidReason = 'Este email ya está en uso'
          } else {
            this.isEmailInvalidReason = 'Hubo un problema validando tu email'
          }
          this.isEmailValid = false
        })
        .finally(() => {
          this.emailValidationLoading = false
        })
    },
    isEmailFormatValid () {
      const emailFormatExpresion = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
      return emailFormatExpresion.test(this.email)
    },
    hydrateInfo () {
      if (this.company !== null && Object.keys(this.company).length > 1) {
        this.name = this.company.userName
        this.companyName = this.company.name
        this.email = this.company.userMail
        this.isNameValid = this.isCompanyNameValid = this.isEmailValid = true
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.email-feedback
  display: flex
  justify-content: space-between
  height: 40px
</style>

