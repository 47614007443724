<template>
  <div class="mb-5">
    <button
      v-if="showBackButton"
      type="button"
      class="btn btn btn-outline-secondary mt-5 me-2"
      @click="$emit('back')"
    >
      <i class="fas fa-arrow-left" />
    </button>
    <button
      type="button"
      class="btn btn-primary mt-5"
      :disabled="!nextAllowed"
      @click="$emit('next')"
    >
      <template v-if="loading">
        {{ loadingLabel }}
        <span class="spinner-border spinner-border-sm spinner-white" role="status" aria-hidden="true" />
      </template>
      <template v-if="!loading">
        {{ nextLabel }}
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'StepButtons',
  props: {
    showBackButton: { type: Boolean, default: true },
    nextAllowed: Boolean,
    nextLabel: { type: String, default: 'Siguiente '},
    loading: Boolean.lengt,
    loadingLabel: { type: String, default: 'Procesando...'},
  },
  data() {
    return {
      currentValue: ''
    }
  }
}
</script>
