<template>
  <div class="form-floating mb-3">
    <input
      v-model="currentValue"
      :type="type || 'text'"
      class="form-control"
      :class="{'is-invalid': isValid === false, 'is-valid': isValid === true}"
      :disabled="disabled"
      :id="inputName"
      :name="inputName"
      :placeholder="label"
      :maxlength="maxlength || ''"
      @input="$emit('input', currentValue)"
    >
    <label :for="inputName">
      {{ label }}
    </label>
    <div class="invalid-feedback">
      {{ invalidReason || ''}}
    </div>
    <div class="valid-feedback">
      {{ validReason || ''}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    maxlength: Number,
    type: String,
    label: String,
    isValid: {
      type: Boolean,
      default: null
    },
    invalidReason: String,
    validReason: String,
    disabled: Boolean
  },
  data() {
    return {
      currentValue: ''
    }
  },
  watch: {
    value (newValue) {
      this.currentValue = newValue
    }
  },
  created () {
    this.currentValue = this.value
  },
  computed: {
    inputName () {
      return this.label.replace(/ /g, "").toLowerCase() + 'Input'
    }
  }
}
</script>
