<template>
  <table>
    <thead>
      <th style="width:25px">
        <i class="fas fa-rocket"/>
      </th>
      <th colspan="2">
        Características
      </th>
    </thead>
    <tbody>
      <tr>
        <td colspan="2">
          <p class="mb-0">Referencias</p>
        </td>
        <td class="text-end">
          <p class="mb-0"> {{ plan.references }} </p>
        </td>
      </tr>
      <tr v-if="plan.features.dynamicPricing">
        <td colspan="2">
          <p class="mb-0">Dynamic Pricing</p>
        </td>
        <td class="text-end">
          <i class="fas fa-check-circle"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>


export default {
  name: 'Features',
  props: {
    plan: Object
  }
}
</script>
