<template>
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">Cargando...</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>
