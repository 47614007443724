<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-9">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="text-center mt-2">
            <h5>Selecciona un Plan</h5>
          </div>
        </div>
        <div 
          v-for="(plan, key) in plans"
          :key="key"
          class="col-12 col-md-6 col-xl-4"
        >
          <Plan
            :ref="plan.id"
            :plan="plan"
            :selected-plan="selectedPlan"
            :should-be-editable="shouldBeEditable"
            @plan-changed="$emit('plan-changed', $event)"
          />
        </div>
        <div class="col-12">
          <div class="text-center mt-2">
            <span>
              ¿Necesitas más de 10.000 referencias? contáctanos
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#supportModal"
              >
                aquí
                <i class="fas fa-external-link-alt" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <SupportModal
        :modalId="'supportModal'"
        :support-details="supportDetails"
      />
    </div>
    <div class="col-12 col-lg-9 text-end">
      <StepButtons
        :next-allowed="nextAllowed"
        @next="$emit('next')"
        @back="$emit('back')"
      />
    </div>
  </div>
</template>
<script>
import Plan from './Plan.vue'
import StepButtons from '../shared/StepButtons.vue'
import SupportModal from '../shared/SupportModal.vue'

export default {
  name: 'PlanSelectionStep',
  components: { Plan, StepButtons, SupportModal },
  props: {
    isPayed: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Object,
      default: () => {}
    },
    supportDetails: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      plans: [
        {
          id: 'basic',
          title: 'Plan Básico',
          description: 'Perfecto para comenzar a rastrear los precios de sus competidores en sus productos',
          price: 39.00,
          references: 1500,
          features: {
            dynamicPricing: false
          },
          addon: {
            title: '+500 referencias',
            price: 12.00
          }
        },
        {
          id: 'advanced',
          title: 'Plan Avanzado',
          description: 'Impulsado por la tecnología Dynamic Pricing, actualice sus productos automáticamente para tener siempre el mejor precio',
          price: 95.00,
          references: '1.500',
          features: {
            dynamicPricing: true,
          },
          addon: {
            title: '+500 referencias',
            price: 25.00
          }
        },
        {
          id: 'professional',
          title: 'Plan Profesional',
          description: 'Dynamic Pricing en 10.000 productos, control completo de su catálogo en un solo lugar',
          price: 295.00,
          references: '10.000',
          features: {
            dynamicPricing: true
          },
          addon: {
            title: '+500 referencias',
            price: 25.00
          }
        }
      ],
      selectedPlan: null,
      addonsQuantity: 0
    }
  },
  activated () {
    document.title = 'Selecciona un Plan | Crea una cuenta'
  },
  computed: {
    shouldBeEditable () {
      return !this.isPayed
    },
    nextAllowed () {
      return this.selectedPlan !== null
    }
  },
  watch: {
    plan (newValue) {
      this.selectedPlan = newValue.id
    }
  },
  mounted () {
    if (this.plan && this.plan.id && this.plan.id !== null) {
      this.selectedPlan = this.plan.id
      this.$refs[this.plan.id][0].setQuantity(this.plan.addonsCount)
    }
  }
}
</script>

<style lang="sass">

@import ../../assets/css/_colors

.card
  &.plan-basic
    border-top: 10px solid #e9ecef

  &.plan-advanced
    border-top: 10px solid gray

  &.plan-professional
    border-top: 10px solid $color-primary

  &.selected
    box-shadow: 0 0 0 0.2rem $color-primary-shadow

  &:hover
    box-shadow: 0 0 0 0.25rem $color-primary-shadow
    cursor: pointer

.card-title
  margin-bottom: 30px

.card-footer
  background-color: white
  border-top: none

.fa-check-circle
  color: $green-check

.selected-icon
  color: $color-primary

.discarded
  opacity: 0.4
  
</style>
