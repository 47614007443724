<template>
  <div class="row justify-content-center align-items-center">
    <StepTracker
      :step="step"
      @step-changed="goNext($event)" 
    />
    <keep-alive>
      <CouponStep
        v-if="step === 1"
        @next="goNext"
        @sign-info="checkSingInInfo($event)"
      />
      <CompanyStep
        v-if="step === 2"
        :is-payed="isPayed"
        :company="company"
        @company-changed="companyChanged($event)"
        @next="goNext"
        @back="goBack"
      />
      <PlanSelectionStep
        v-if="step === 3"
        :is-payed="isPayed"
        :plan="plan"
        :support-details="supportDetails"
        @plan-changed="planChanged($event)"
        @next="goNext"
        @back="goBack"
      />
      <ApiStep
        v-if="step === 4"
        :loading="apiKeyLoading"
        :request-failed="apiRequestFailed"
        @apikey-changed="apiKeyChanged($event)"
        @next="sendApiKey"
        @back="goBack"
      />
      <LastStep
        v-if="step === 6"
        :company="company"
      />
    </keep-alive>
  </div>
</template>

<script>
import StepTracker from './shared/StepTracker.vue'
import CouponStep from './couponStep/CouponStep.vue'
import PlanSelectionStep from './planSelectionStep/PlanSelectionStep.vue'
import CompanyStep from './companyStep/CompanyStep.vue'
import ApiStep from './apiStep/ApiStep.vue'
import LastStep from './lastStep/LastStep.vue'
import SignUp from '../api/SignUp'


export default {
  name: 'SignUp',
  components: { StepTracker, CouponStep, PlanSelectionStep, CompanyStep, ApiStep, LastStep },
  data () {
    return {
      step: 1,
      isPayed: false,
      coupon: null,
      plan: null,
      planPrice: null,
      company: null,
      miraklToken: null,
      payment: null,
      apiKey: null,
      paymentLoading: false,
      apiKeyLoading: false,
      paymentRequestFailed: null,
      apiRequestFailed: false
    }
  },
  computed : {
    supportDetails () {
      const supportDetails = {}
      if (this.coupon !== null) {
        supportDetails.coupon = this.coupon
      }
      if (this.company !== null) {
        supportDetails.userName = this.company.userName
        supportDetails.userMail = this.company.userMail
      }
      return supportDetails
    }
  },
  methods: {
    goNext (specificStep) {
      specificStep
        ? this.step = specificStep
        : this.step += 1
    },
    goBack () {
      this.step -= 1
    },
    checkSingInInfo (info) {
      this.hydrateSignInInfo(info)
      this.isPayed = info.payed
      if (info.miraklToken) {
        this.goNext(6)
        return
      }
      if (info.payed) {
        this.goNext(5)
      }
    },
    planChanged({id, addonQuantity, planPrice}) {
      this.plan = { id, addons: addonQuantity}
      this.planPrice = planPrice
    },
    companyChanged({companyName, userName, userMail}) {
      this.company = {companyName, userName, userMail}
    },
    paymentChanged ({cardNumber, expirationDate, cvv}) {
      this.payment = {cardNumber, expirationDate, cvv}
    },
    processPayment () {
      const couponData = {
        coupon: this.coupon,
        company: this.company,
        plan: this.plan,
        payment: this.payment
      }
      this.paymentLoading = true
      SignUp.postCouponData(couponData)
        .then(response => {
          this.paymentRequestFailed = false
          this.isPayed = response.payed
        })
        .catch(() => {
          this.paymentRequestFailed = true
        })
        .finally(() => {
          this.paymentLoading = false
        })
    },
    sendApiKey () {
      const couponData = {
        coupon: this.coupon,
        miraklToken: this.apiKey,
        company: this.company,
        plan: this.plan
      }
      this.apiKeyLoading = true
      SignUp.postCouponData(couponData)
        .then(() => {
          this.goNext(6)
          this.apiRequestFailed = false
        })
        .catch(() => {
          this.apiRequestFailed = true
        })
        .finally(() => {
          this.apiKeyLoading = false
        })
    },
    apiKeyChanged (apikey) {
      this.apiKey = apikey
    },
    hydrateSignInInfo (info) {
      this.coupon = info.coupon
      this.plan = info.plan
      this.company = info.company
      this.miraklToken = info.miraklToken
    },
    handlePaymentNext () {
      return this.isPayed
        ? this.goNext(5)
        : this.processPayment()
    }
  }
}
</script>
