<template>
  <div class="col-12 col-xl-8">
    <div class="d-xl-none col-12">
      <div class="d-flex justify-content-center align-items-center mt-5">
        <img
          class="logo-image"
          src="../../assets/logo_combined.png"
        >
      </div>
    </div>
    <h1 class="title text-center mb-5">
      Crea una cuenta
    </h1>
    <div class="tracker-width">
      <div class="position-relative  mb-5 ms-3 me-3">
        <div class="progress progress-height">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: widthValue + '%'}"
            :aria-valuenow="widthValue"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <button
          v-for="(step, key) in steps"
          :key="key"
          type="button"
          class="btn btn-sm step-button"
          :class="[btnClasses(step)]"
          :disabled="isDisabled(step.number)"
          @click="$emit('step-changed', step.number)"
          v-tooltip="{ content: step.tooltip, classes: [tooltipClass(step.number)] }"
        >
          {{ step.number}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'StepTracker',
  props: {
    step: Number
  },
  data () {
    return {
      steps: [
        { number: 1, startclass: 'start-0', tooltip: 'Introduce tu Cupón' },
        { number: 2, startclass: 'start-33', tooltip: 'Tu información' },
        { number: 3, startclass: 'start-66', tooltip: 'Selecciona un Plan' },
        { number: 4, startclass: 'start-100', tooltip: 'Configura tu Tienda' }
      ]
    }
  },
  computed: {
    widthValue () {
      const percentage = {
        1: 0,
        2: 33,
        3: 66,
        4: 100,
        5: 100,
        6: 100
      }
      return percentage[this.step]
    },
    devMode () {
      return process.env.VUE_APP_MODE === 'dev'
    }
  },
  methods: {
    btnClasses ({number, startclass}) {
      const currentBtnClass = number <= this.step ? 'btn-primary' : 'btn-secondary'
      return [currentBtnClass, startclass]
    },
    isDisabled (number) {
      const isSignUpDone = this.step === 6
      const isPreviousStep = number > this.step
       return this.devMode
        ? false
        : isPreviousStep || isSignUpDone
    },
    tooltipClass (number) {
      return number > this.step
      ? 'tooltip-secondary'
      : ''
    }
  }
}
</script>

<style lang="sass" scoped>

.title
  margin: 40px 0px
  text-align: center

.tracker-width
  width: 80%
  margin: auto

.progress-height
  height: 5px

.start-25
  left: 25%!important

.start-75
  left: 75%!important

.step-button
  width: 2rem
  height: 2rem
  border-radius: 50rem!important
  transform: translate(-50%,-50%)!important
  position: absolute!important
  top: 0!important

.btn:disabled
  opacity: 1

.start-33 
  left: 33% !important;

.start-66 
  left: 66% !important;
  
</style>
