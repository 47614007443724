<template>
  <div class="row justify-content-center align-items-center">
    <div class="d-none d-md-block col-md-5 col-xl-4">
      <div class="image-container">
        <img class="background-signup" src="../../assets/images/signup.jpg">
      </div>
    </div>
    <div class="col-10 col-md-6 col-xl-4">
      <h5 class="text-start">
        Configura tu Tienda
      </h5>
      <CustomInput
        v-model="apiKey"
        :is-valid="isApiKeyValid"
        label="API Key"
      />
      <div style="min-height: 2em">
        <p v-if="requestFailed" class="text-danger">
          Hubo un problema creando tu cuenta. Puede que el Token no sea válido
        </p>
      </div>
      <div>
        <h6 class="faq-title">
          Preguntas frecuentes
        </h6>
        <div id="accordionExample" class="accordion">
          <div class="accordion-item">
            <h2 id="headingOne" class="accordion-header">
              <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="accordion-button collapsed">
                ¿Donde está mi API Key?
              </button>
            </h2>
            <div id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
              <div class="accordion-body">
                <ul>
                  <li>Ve a tu panel de administración de PcComponentes</li>
                  <li>En la parte superior derecha de la pantalla, haz click en tu nombre de usuario.</li>
                  <li>Haz click en Contraseña API</li>
                  <li>Pégala aquí</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 id="headingTwo" class="accordion-header">
              <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" class="accordion-button collapsed">
                ¿Por qué necesitamos tu API Key?
              </button>
            </h2>
            <div id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" class="accordion-collapse collapse">
              <div class="accordion-body">
                Para poder comunicar tu tienda online con nuestro servicio via API, necesitamos un token de autorización. De este modo podremos obtener tu catálogo de productos.
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="col-10 col-md-11 col-xl-8 text-end">
      <StepButtons
        :next-allowed="nextAllowed"
        :loading="loading"
        :nextLabel="'Finalizar'"
        @next="$emit('next')"
        @back="$emit('back')"
      />
    </div>
  </div>
</template>

<script>
import CustomInput from '../shared/CustomInput.vue'
import StepButtons from '../shared/StepButtons.vue'

export default {
  name: 'ApiStep',
  components: { CustomInput, StepButtons },
  props: {
    loading: Boolean,
    requestFailed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiKey: '',
      isApiKeyValid: null
    }
  },
  activated () {
    document.title = 'Configura tu tienda | Crea una cuenta'
  },
  watch: {
    apiKey () {
      this.apiKeyChanged()
      this.isApiKeyValid = this.isApiKeyFormatValid()
    }
  },
  computed: {
    nextAllowed () {
      return this.isApiKeyValid
    },
  },
  methods: {
    apiKeyChanged () {
      this.$emit('apikey-changed', this.apiKey)
    },
    isApiKeyFormatValid () {
      const tokenFormatExpresion = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/
      return tokenFormatExpresion.test(this.apiKey)
    }
  }
}
</script>

<style lang="sass" scoped>
.faq-title
  padding-inline-start: 10px
  margin-bottom: 0px
  font-weight: bold
</style>
