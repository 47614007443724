<template >
  <table>
    <thead>
      <th style="width:25px">
        <i class="fas fa-puzzle-piece"/>
      </th>
      <th colspan="2">
        Complementos
      </th>
    </thead>
    <tbody>
      <tr>
        <td colspan="2">
          <p class="mb-0"> {{ plan.addon.title }} </p>
        </td>
        <td class="text-end">
          <div v-if="quantity === 0">
            <button
              class="btn btn-sm rounded-pill btn-gray"
              style="width: auto;height: auto; padding: 2px 8px"
              :disabled="!shouldBeEditable"
              @click="increment"
            >
              Añadir
            </button>
          </div>
          <div
            v-if="quantity !== 0"
            class="d-flex align-items-center justify-content-end"
          >
            <button
              class="btn btn-sm rounded-pill btn-gray"
              :disabled="!shouldBeEditable"
              @click="decrement"
            >
              <div
                class="d-flex justify-content-center align-items-center"
              >
                <i class="fas fa-minus" aria-hidden="true"/>
              </div>
            </button>
            <span class="ms-2 me-2"> {{ quantity }} </span>
            <button
              class="btn btn-sm rounded-pill btn-gray"
              :disabled="!shouldBeEditable"
              @click="increment"
            >
              <div class="d-flex justify-content-center align-items-center">
                <i class="fas fa-plus" />
              </div>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'Addons',
  props: {
    plan: Object,
    shouldBeEditable: Boolean
  },
  data () {
    return {
      quantity: 0
    }
  },
  methods: {
    increment () {
      this.quantity+=1
      this.$emit('quantity-changed', this.quantity)
    },
    decrement () {
      this.quantity-=1
      this.$emit('quantity-changed', this.quantity)
    },
    setQuantity (quantity) {
      this.quantity = quantity
      this.$emit('quantity-changed', this.quantity)
    }
  }
}

</script>

<style lang="sass" scoped>

.addon
  height: 30px
  padding-left: 10px
  margin: 10px 0px

.addon-box
  display: flex
  justify-content: space-between
  align-items: center
  height: 100%

.btn-gray 
  color: #fff
  background-color: #607d8b
  border-color: #607d8b
  width: 1.5rem
  height: 1.5rem
  &:focus
    box-shadow: none
  &:hover
    color: white

</style>
