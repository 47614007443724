import { apiGet, apiPost } from './baseApi'

export default {
  postCouponData (couponData) {
    return apiPost('admin/api/pccomponentes/signup', couponData)
      .then(response => {
        return Promise.resolve(response.data.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data.error.code)
      })
  },
  checkEmailAvailable (mail) {
    return apiGet('admin/api/pccomponentes/user/validate' + '?mail=' + mail)
      .then(response => {
        return Promise.resolve(response.data.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data.error.code)
      })
  },
  requestSupport (userDetails) {
    return apiPost('admin/api/pccomponentes/user/support', userDetails)
      .then(response => {
        return Promise.resolve(response.data.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data.error.code)
      })
  }
}
