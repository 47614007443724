import Axios from 'axios'

const authToken = '6934e5ca20121be59166fed577a867f4cc56c4fe0ddeb7ff365513121b34f085ac08f70ee7a4eb49'

const axios = Axios.create({
  'baseURL': process.env.VUE_APP_API_URL,
  headers: {
    'X-AUTH-TOKEN': authToken,
    'Content-type': 'application/json; charset=UTF-8',
  }
})

const apiGet = (url, params, options = {}) => {
  return axios.get(url, options)
}
const apiPost = (url, body) => {
  return axios.post(url, parseBody(body))
}
const apiPatch = (url, params, body) => {
  return axios.patch(url, body)
}
const apiDelete = (url, params, body) => {
  return axios.delete(url, { data: body })
}

function parseBody (body) {
  return JSON.stringify({ data: body })
}

export {
  apiGet,
  apiPost,
  apiPatch,
  apiDelete
}
